import useAxios from "axios-hooks";
import React, { useContext, useEffect, useState } from "react";
import { DateRangeInput, Icon, Tooltip } from "rt-design-system-backup";
import * as XLSX from "xlsx";
import { getExcelRow, url } from "../../../helpers";
import SocketContext from "../..//SocketContext";
import Page from "../../Page";
import STPAPIConsoleTable from "../../STPAPIConsoleTable/STPAPIConsoleTable";
import Toaster from "../../Toaster";
import { dateFormat } from "../Maturities";
import axios from "axios";
import "./style.scss";

//Icons
import ReconcileIcon from "../../Icons/reconcile";

const STPAPIConsole = () => {
  const today = new Date();
  const last_week = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate(),
  );
  const initial_start_date = localStorage.getItem("filter_start_date");
  const [startDate, setStartDate] = useState(
    (initial_start_date && new Date(initial_start_date)) || last_week,
  );
  const initial_end_date = localStorage.getItem("filter_end_date");
  const [endDate, setEndDate] = useState(
    (initial_end_date && new Date(initial_end_date)) || new Date(),
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const socket = useContext(SocketContext);

  const fetchDataAndUpdateUI = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        url(
          `/trade_sync/?start_date=${dateFormat.formatDate(
            startDate,
          )}&end_date=${dateFormat.formatDate(endDate)}`,
        ),
      );
      const formattedData = response.data.map((item) => ({
        key: item.id,
        id: item.id,
        DDPId: item.alu_trade,
        requestId: item.request_id,
        fusionID: item.system_id,
        acknowledgementMessage: item.response_message,
        status: item.status,
        createdAt: item.created_at,
        system: item.system,
        payload: item.payload,
      }));
      setData(formattedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const refetchNewRow = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(url(`/trade_sync/${id}/`));
      const updatedRowData = response.data; // Assuming this is the updated row data

      // Format the updated row data as needed
      const formattedUpdatedRow = {
        key: updatedRowData.id,
        id: updatedRowData.id,
        DDPId: updatedRowData.alu_trade,
        requestId: updatedRowData.request_id,
        fusionID: updatedRowData.system_id,
        acknowledgementMessage: updatedRowData.response_message,
        status: updatedRowData.status,
        createdAt: updatedRowData.created_at,
        system: updatedRowData.system,
        payload: updatedRowData.payload,
      };

      // Replace the old row with the updated one
      const newData = data.map((item) =>
        item.id === id ? formattedUpdatedRow : item,
      );

      setData(newData); // Update the state with the new data array
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const [, executeUpdate] = useAxios(
    {
      url: url("/trade_sync/update_system_ids/"),
      method: "PUT",
    },
    { manual: true },
  );

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        const validData = json.filter(
          (row) => row["ID"] && row["Trade Number"],
        );
        uploadAndUpdateSystemIds(validData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const uploadAndUpdateSystemIds = async (csvData) => {
    // Transform the data to the required format
    const payload = csvData.map((row) => ({
      trade_sync_id: Number(row["ID"]),
      system: "Fusion",
      system_id: row["Trade Number"],
    }));

    try {
      const response = await executeUpdate({ data: payload });
      if (response.status === 200) {
        Toaster.show({
          message: "Bulk upload successful",
          intent: "success",
        });
        fetchDataAndUpdateUI();
      }
    } catch (error) {
      Toaster.show({
        message: "Error during bulk upload",
        intent: "danger",
      });
      console.error("Error during bulk upload:", error);
    }
  };

  const handleUploadClick = () => {
    document.getElementById("file-upload").click();
  };

  useEffect(() => {
    fetchDataAndUpdateUI();
  }, [startDate, endDate]);

  useEffect(() => {
    const updateLastUpdatedAt = (socketData) => {
      const incomingData = socketData.detail;
      try {
        if (typeof incomingData.payload === "string") {
          incomingData.payload = JSON.parse(incomingData.payload);
        }
      } catch (e) {
        console.error("Error parsing payload", e);
      }
      setData((currentData) => {
        const dataIndex = currentData.findIndex(
          (item) => item.id === incomingData.id,
        );
        if (dataIndex !== -1) {
          // Update the item at dataIndex
          const updatedData = [...currentData];
          updatedData[dataIndex] = {
            key: incomingData.id,
            id: incomingData.id,
            DDPId: incomingData.alu_trade,
            requestId: incomingData.request_id,
            fusionID: incomingData.system_id,
            acknowledgementMessage: incomingData.response_message,
            status: incomingData.status,
            createdAt: incomingData.created_at,
            system: incomingData.system,
            payload: incomingData.payload,
          };
          return updatedData;
        }
        return currentData;
      });
    };

    socket.addEventListener("trade_sync_to_console", updateLastUpdatedAt);
    return () =>
      socket.removeEventListener("trade_sync_to_console", updateLastUpdatedAt);
  }, [socket, setData]);

  const downloadXlsx = () => {
    const selectedRowData =
      selectedRow.length > 0
        ? data.filter((record) => selectedRow.includes(record.id))
        : data;

    // Initialize an array to store data for the StandardFormulaVariables sheet
    let standardFormulaVariablesData = [];

    const transformedData = selectedRowData
      .map((record) => {
        let parsedPayload = [];
        try {
          parsedPayload = JSON.parse(record.payload.trim());
        } catch (error) {
          console.error(
            "Error parsing payload for record id:",
            record.id,
            error,
          );
        }

        const parsedData = parsedPayload[0];

        if (!parsedData || !parsedData?.Trade_Status || !parsedData.Price)
          return {};

        const excelRow = getExcelRow(
          parsedData,
          record.fusionID,
          record.id,
          record.DDPId,
        );

        // Check if Is_Standard_Formula is true and process legs
        if (parsedData.Is_Standard_Formula === "True") {
          let legNumber = 1;
          while (parsedData[`Leg${legNumber}_From_Date`]) {
            standardFormulaVariablesData.push({
              "Trade Sr No": record.DDPId,
              "Standard Formula": parsedData.Standard_Formula_Code,
              Variable: `Month Avg ${legNumber}`,
              "Start Date": parsedData[`Leg${legNumber}_From_Date`],
              "End Date": parsedData[`Leg${legNumber}_To_Date`],
            });
            legNumber++;
          }
        }

        return excelRow;
      })
      .filter((row) => Object.keys(row).length > 0); // Filter out empty objects

    const ws = XLSX.utils.json_to_sheet(transformedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Add StandardFormulaVariables sheet if there are any rows to add
    if (standardFormulaVariablesData.length > 0) {
      const wsFormula = XLSX.utils.json_to_sheet(standardFormulaVariablesData);
      XLSX.utils.book_append_sheet(wb, wsFormula, "StandardFormulaVariables");
    }

    XLSX.writeFile(wb, "tradesync.xlsx");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const filterEligibleRows = (selectedRows, data) => {
    return selectedRows.filter((rowId) => {
      const item = data.find((item) => item.id === rowId);
      return (
        item && !["Waiting", "In progress", "Manual sent"].includes(item.status)
      );
    });
  };

  const handleSendAction = async (
    selectedRows,
    data,
    actionUrlTemplate,
    successMessage,
    errorMessage,
  ) => {
    if (selectedRows.length === 0) {
      Toaster.show({
        message: "No rows selected",
        intent: "danger",
      });
      return;
    }

    const eligibleRows = filterEligibleRows(selectedRows, data);

    if (eligibleRows.length === 0) {
      Toaster.show({
        message: "No eligible rows for action based on status criteria",
        intent: "warning",
      });
      return;
    }

    const handleResponse = (promise) =>
      promise
        .then(() => ({ status: "fulfilled" }))
        .catch(() => ({ status: "rejected" }));

    const promises = eligibleRows.map((row) =>
      handleResponse(axios.put(actionUrlTemplate(row))),
    );

    const results = await Promise.allSettled(promises);
    const allSuccessful = results.every(
      (result) => result.status === "fulfilled",
    );

    if (allSuccessful) {
      Toaster.show({
        message: successMessage,
        intent: "success",
      });
      setSelectedRow([]);
      await fetchDataAndUpdateUI();
    } else {
      Toaster.show({
        message: errorMessage,
        intent: "danger",
      });
    }
  };

  const handleManualSent = () =>
    handleSendAction(
      selectedRow,
      data,
      (row) => url(`/trade_sync/${row}/mark_as_sent/`),
      "All trades marked as Manual sent",
      "Some operations failed",
    );
  const handleResend = () =>
    handleSendAction(
      selectedRow,
      data,
      (row) => url(`/trade_sync/${row}/resend/`),
      "All trades sent for resend",
      "Some operations failed",
    );
  const handleRegenerateAndResend = () =>
    handleSendAction(
      selectedRow,
      data,
      (row) => url(`/trade_sync/${row}/regenerate_and_resend/`),
      "All eligible trades sent for regenerate and resend",
      "Some operations failed",
    );

  const handleDateChange = (data) => {
    const start = data[0] || new Date();
    const end = data[1] || new Date();

    setStartDate(start);
    setEndDate(end);

    localStorage.setItem(`filter_start_date`, start);
    localStorage.setItem(`filter_end_date`, end);
  };

  return (
    <Page
      title="STP API Console"
      tabs={[]}
      rightActions={
        <div className="stp-api__right-action-box">
          <div className="stp-api__right-action-row">
            <div className="stp__api__viewing">
              <span style={{ fontSize: "18px", fontWeight: "normal" }}>
                Viewing:
              </span>
              <DateRangeInput
                singleMonthOnly
                allowSingleDayRange
                {...dateFormat}
                popoverProps={{ minimal: true }}
                value={[startDate, endDate]}
                onChange={handleDateChange}
              />
            </div>

            <div className="stp-api__right-action-links">
              <Tooltip intent="primary" content="Regenerate and Resend">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleResend}
                >
                  <Icon icon={"repeat"} style={{ cursor: "pointer" }} />
                </div>
              </Tooltip>
              <Tooltip
                intent="primary"
                content="Change Status to Manually Sent"
              >
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleManualSent}
                >
                  <ReconcileIcon />
                </div>
              </Tooltip>
              <Tooltip intent="primary" content="Downloads APIs">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Icon iconSize={20} icon="download" onClick={downloadXlsx} />
                </div>
              </Tooltip>
              <Tooltip intent="primary" content="Upload Fusion IDs manually">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    iconSize={20}
                    icon="upload"
                    onClick={handleUploadClick}
                  />
                </div>
              </Tooltip>
            </div>
          </div>

          <input
            type="file"
            id="file-upload"
            style={{ display: "none" }}
            onChange={handleFileUpload}
            accept=".xlsx, .xls"
          />
        </div>
      }
    >
      <STPAPIConsoleTable
        data={data}
        loading={loading}
        reload={refetchNewRow}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </Page>
  );
};
export default STPAPIConsole;
